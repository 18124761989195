import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar";
import Shop from "./pages/shop/Shop";
import Cart from "./pages/cart/Cart";
import ShopContextProvider from "./context/shop-context";

function App() {
	return (
		<div className="App">
			<ShopContextProvider>
				<Router>
					<Navbar />
					<Routes>
						<Route path="/" element={<Shop />}></Route>
						<Route path="/cart" element={<Cart />}></Route>
					</Routes>
				</Router>
			</ShopContextProvider>
		</div>
	);
}

export default App;
